<template>
    <div class="animated fadeIn">
        <h1>Unternehmen</h1>
        <div class="row align-items-center float-right col-3">
            <router-link :to="'/company/editCompany/-1'" tag="button" class="btn margin-bottom-10 btn-primary btn-block">Unternehmen anlegen</router-link>
        </div>
        <div id="geoLocations">
            <v-client-table ref="table" :api-mode="false" :data="companyEntries" :columns="columns" :options="options">
                <template slot="edit" slot-scope="props">

                    <router-link :to="{ name: 'Stellenangebote', params: { id: props.row.id }, query: { company: props.row.name } }" class="mr-2">
                        <a class="fa fa-building-o fa-lg"></a> Jobs
                    </router-link>

                    <router-link :to="'/company/editCompany/'+props.row.id" class="mr-2">
                        <a class="fa fa-edit fa-lg"></a>
                    </router-link>
                    &nbsp;
                    <a class="fa fa-trash fa-lg text-primary" @click="warningModal = true, selectedCompany = props.row.id"></a>
                    &nbsp;
                </template>
            </v-client-table>
        </div>

        <!-- Modal Component -->
        <b-modal title="Unternehmen löschen?" class="modal-warning" v-model="warningModal" @ok="deleteCompany()" ok-variant="warning">
            Wollen Sie das Unternehmen wirklich löschen?
        </b-modal>

    </div>
</template>

<script>
    import Vue from 'vue'
    import {ClientTable} from 'vue-tables-2'
    import {mapGetters} from "vuex";

    Vue.use(ClientTable)


    export default {
        name: 'companies',
        data () {
            return {
                warningModal: false,
                nrOfEntries: 0,
                columns: ['name', 'slug', 'url','edit'],
                options: {
                    headings: {
                        edit: '',
                        url: 'Webseite'
                    },
                    filterable: true,
                    sortable: [ 'title' ],
                    sortIcon: {
                        base: 'fa',
                        up: 'fa-sort-up',
                        down: 'fa-sort-down',
                        is: 'fa-sort'
                    },
                    texts: {
                        //count: 'Showing {from} to {to} of {count} records|{count} records|One record - Test',
                        count: '',
                        filterPlaceholder: 'Suche...',
                    },
                    pagination: {
                        nav: 'scroll'
                    },
                }
            }
        },
        computed: {
            ...mapGetters({
                companyEntries: 'company/getCompanyEntries',
            })
        },
        mounted() {
            this.$store.dispatch('company/loadAllCompanyEntries')
        },
        methods: {
            deleteCompany() {
                this.$store.dispatch('company/deleteCompany', this.selectedCompany )
            },
        },

    }
</script>
